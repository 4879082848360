<template>
  <div id="app">
    <SiteNav />
    <router-view/>
  </div>
</template>

<script>
import SiteNav from "@/components/SiteNav";
export default {
  components: {
    SiteNav
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

html, body, #app{
  background: $darkblue;
  color: #fff;
}
#app {
  font-family: 'Mulish', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  padding-top: 40px;
}

*{
  font-family: 'Mulish', sans-serif;
  box-sizing: border-box;
}

button, .button{
  display: inline-block;
  text-decoration: none;
  background: #343766;
  border: none;
  appearance: none;
  border-radius: 15px;
  color: #fff;
  padding: 15px 30px;
  margin: 7px;
  font-size: 14px;
  cursor: pointer;
  &:disabled{
    opacity: 0.3;
    cursor: no-drop;
  }
  &:focus{
    outline: none;
    box-shadow: inset 0 0 3px 0 rgba(#fff, 0.2);
  }
  &.is-danger{
    background: $red;
  }
  &.is-grey{
    background: #ccc;
  }
}
</style>
