<template>
  <div>
    <h3>Settings</h3>
    <div class="settings" :class="{disabled}">
      <span>Delete all data</span>
      <button v-if="!deleting" class="is-danger" @click="deleting = true">Delete</button>
      <div class="settings--confirm" v-if="deleting">
        <button class="is-grey"  @click="deleting = false">Cancel</button>
        <button class="is-danger" @click="deleteAllData()">Confirm</button>
      </div>
    </div>
    <svg class="spinner" width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="thinking">
      <g class="spinner--line">
        <circle stroke="#ffffff" stroke-width="3" cx="20" cy="20" r="15"></circle>
      </g>
    </svg>
  </div>
</template>

<script>
import DSets from "@/components/DSets";
import { mapState } from 'vuex';

export default {
  name: 'Settings',
  data(){
    return {
      deleting: false,
      thinking: false,
      disabled: false,
    }
  },
  methods: {
    deleteAllData(){
      this.disabled = true;
      this.thinking = true;
      window.localStorage.removeItem('app');
      setTimeout(() => {
        this.$router.push("/");
        window.location.reload(true);
      }, 1500);
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.settings{
  width: 320px;
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 20px;
  background: #fff;
  color: $black;
  @include biground;
  button, .button{
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 0;
  }
  &--confirm{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
  &.disabled{
    opacity: 0.4;
  }
}

$length: 119.380520836;
$speed: 2s;

@keyframes spinnerLineSpin{
  0%{ stroke-dasharray: 0 0 $length 0; }
  50%{ stroke-dasharray: 0 $length $length 0; }
  100%{ stroke-dasharray: $length $length 0 0; }
}

@keyframes spinnerRotate{
  0%{ transform: rotate(0deg); }
  100%{ transform: rotate(360deg); }
}

.spinner{
  margin-top: 20px;
  animation: spinnerRotate $speed linear infinite;
}
.spinner--line{
  animation: spinnerLineSpin $speed linear infinite;
}
</style>