let localStorageAvailable = () => {
    let test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}


let updateLocalStorage = (state) => {
  localStorage.setItem('app', JSON.stringify(state));
}

let setupLocalStorage = (store) => {
  let previous = localStorage.getItem('app');

  if(previous){
    store.replaceState(JSON.parse(previous));
  }
}

const VuexLocalStorage = (store) => {

  if( ! localStorageAvailable() ){
    return false;
  }

   console.log("Remember, local storage is overwriting vuex store!!!");

  setupLocalStorage(store);

  store.subscribe((mutation, state) => {
      updateLocalStorage(state)
  })
}

export default VuexLocalStorage;