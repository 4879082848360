<template>
  <div class="workout">
    <DSets
      v-for="(group, g) in workout.groups"
      :group="group"
      :g="g"
      :key="'g'+g"
      :editing="editing"
      :disabled="false"
      :canDeleteGroup="canDeleteGroup"/>
    <div v-if="editing" class="sets add-button">
      <!-- Editing: -->
      <button class="delete" @click="addGroup()">Add weight +</button>
    </div>
    <div>
      <button @click="editing = !editing">{{ editing ? 'Save' : 'Edit'}}</button>
    </div>
  </div>
</template>

<script>
import DSets from "@/components/DSets";
import { mapState } from 'vuex';

export default {
  name: 'Home',
  components: {
    DSets
  },
  data(){
    return {
      editing: false
    }
  },
  computed: {
    ...mapState(['workouts', 'currentWorkout']),
    workout(){
      return this.workouts[this.currentWorkout];
    },
    canDeleteGroup(){
      return this.$store.getters.canDeleteGroup(this.workout);
    }
  },
  methods: {
    addGroup(){
      this.$store.commit('addGroup');
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.workout{
  display: grid;
  grid-gap: 20px;
  .add-button{
    background: #fff;
    grid-template-columns: 1fr;
    button, .button{
      background: $green;
    }
  }
}
</style>