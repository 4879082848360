import Vue from 'vue'
import dayjs from 'dayjs'
import Vuex from 'vuex'
import VuexLocalStorage from '@/modules/VuexLocalStorage';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [VuexLocalStorage],
  state: {
    workouts: [{
      id: 1,
      d: dayjs().format('DD-MM-YYYY'),
      s: 5,
      r: 5,
      groups: [{
        w: 20, // Weight...
        s: [0, 0, 0, 0, 0],
      }]
    }],
    currentWorkout: 0
  },
  mutations: {
    updateGroupWeight(state, {v, g}){
      Vue.set(state.workouts[state.currentWorkout].groups[g], 'w', v);
    },
    toggleSet(state, {v, g, s}){
      v = v ? 1 : 0;
      Vue.set(state.workouts[state.currentWorkout].groups[g].s, s, v);
    },
    addGroup(state){
      let workout = state.workouts[state.currentWorkout];
      workout.groups.push(JSON.parse(JSON.stringify(workout.groups[workout.groups.length - 1])));
    },
    deleteGroup(state, {g}){
      let workout = state.workouts[state.currentWorkout];
      workout.groups.splice(g, 1);
    },
    createWorkout(state){
      let oldWorkout = state.workouts[state.workouts.length - 1];
      let newWorkout = JSON.parse(JSON.stringify(oldWorkout));
      newWorkout.groups.forEach(group => {
        group.s = group.s.map(item => 0);
      });
      newWorkout.id = newWorkout.id +1;
      newWorkout.d = dayjs().format('DD-MM-YYYY');
      state.workouts.push(newWorkout);
      state.currentWorkout = state.workouts.length - 1
    },
    deleteWorkout(state, {id}){
      state.workouts = state.workouts.filter(w => w.id != id);
      state.currentWorkout = state.workouts.length - 1
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    canDeleteGroup: state => w => {
      return w.groups.length > 1
    },
    canDeleteWorkout: state => {
      return state.workouts.length > 1
    },
    workout: state => {
      return state.workouts[state.currentWorkout];
    },
    byId: (state) => (id) => {
      return state.workouts.find(thing => thing.id == id)
    }
  }
})
