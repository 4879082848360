<template>
  <div class="workout">
    <DSets v-for="(group, g) in workout.groups" :group="group" :g="g" :key="'g'+g" :editing="editing" :disabled="true"/>
    <div v-if="!deleting">
      <button v-if="canDeleteWorkout" @click="deleting = true">Delete workout</button>
      <router-link class="button" to="/">Go to current workout</router-link>
    </div>
    <div v-if="deleting">
      <p>Really?</p>
      <div>
        <button @click="deleting = false">Cancel</button>
        <button class="is-danger" @click="deleteWorkout()">Delete</button>
      </div>
    </div>
  </div>
</template>

<script>
import DSets from "@/components/DSets";
import { mapGetters } from 'vuex';

export default {
  name: 'Home',
  components: {
    DSets
  },
  data(){
    return {
      editing: false,
      deleting: false,
    }
  },
  computed: {
    ...mapGetters(['canDeleteWorkout']),
    workout(){
      return this.$store.getters.byId(this.$route.params.id);
    }
  },
  methods: {
    addGroup(){
      this.$store.commit('addGroup');
    },
    deleteWorkout(){
      this.$store.commit('deleteWorkout', { id: this.workout.id });
      this.$router.push("/list");
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.workout{
  display: grid;
  grid-gap: 20px;
  .add-button{
    background: #fff;
    grid-template-columns: 1fr;
    button, .button{
      background: $green;
    }
  }
}
</style>