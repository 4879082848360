<template>
<div class="nav-component">
  <label class="nav-label" for="nav-checkbox">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 18H3V16H15V18ZM21 13H3V11H21V13ZM15 8H3V6H15V8Z" fill="#ffffff"/>
    </svg>
  </label>
  <input type="checkbox" id="nav-checkbox" v-model="navOpen" />
  <nav class="site-nav">
    <label class="nav-label" for="nav-checkbox">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="#282b4d"/>
      </svg>
    </label>
    <div class="site-nav--items">
      <router-link @click.native="navOpen = false" to="/">Current workout</router-link>
      <router-link @click.native="newWorkout()" to="/">New workout</router-link>
      <router-link @click.native="navOpen = false" to="/list">List workouts</router-link>
    </div>
    <router-link @click.native="navOpen = false" to="/settings">Settings</router-link>
  </nav>
</div>
</template>

<script>
  export default {
    data(){
      return {
        navOpen: false,
      }
    },
    methods: {
      newWorkout(){
        this.$store.commit('createWorkout');
        this.navOpen = false;
      }
    }
  }
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.site-nav{
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  opacity: 0;
  left: -100vw;
  transition: 0.2s all;
  z-index: 1000;
  padding: 53px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  a{
    text-align: center;
    display: block;
    padding: 15px;
    background: $light;
    color: $darkblue;
    text-decoration: none;
    & + a{
      margin-top: 15px;
    }
  }
}

#nav-checkbox{
  display: none;
  &:checked + .site-nav{
    opacity: 1;
    left: 0vw;
  }
}

.nav-label{
  padding: 15px;
  display: block;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}
.nav-component{
  text-align: right;
}
</style>