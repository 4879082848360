<template>
<div>
  <input type="checkbox" v-model="checked" :id="g + '|' + s" :disabled="disabled" />
  <label :for="g + '|' + s" class="sets--set">
    <div class="tickcross">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path v-if="checked" d="M20.8389 6.69461L8.81811 18.7154L3.16125 13.0586L4.57125 11.6486L8.81811 15.8854L19.4289 5.28461L20.8389 6.69461Z" fill="white"/>
        <path v-else d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z" fill="white"/>
      </svg>
    </div>
  </label>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['g', 's', 'disabled'],
  computed: {
    ...mapGetters(['workout']),
    checked: {
      get () {
        return this.workout.groups[this.g].s[this.s]
      },
      set (v) {
        console.log(v);
        this.$store.commit('toggleSet', {v, g: this.g, s: this.s})
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.tickcross{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
input:checked + .sets--set{
  background: $green;
}
</style>