<template>
  <div class="sets" :class="{editing}">
    <div v-if="!editing" class="sets--weight">{{ group.w }}kg</div>
    <div v-if="!editing" class="sets--complete">
      <div v-for="(set, s) in group.s">
        <TickCross :g="g" :s="s" :disabled="disabled" />
      </div>
    </div>

    <!-- Editing: -->
    <input v-if="editing" type="number" :value="group.w" @input="updateGroupWeight($event, g)" />
    <button v-if="editing" class="delete" @click="deleteGroup" :disabled="!canDeleteGroup">Delete</button>
  </div>
</template>

<script>
import TickCross from "@/components/TickCross";

export default {
  components: {
    TickCross
  },
  props: ['group', 'g', 'editing', 'disabled', 'canDeleteGroup'],
  methods: {
    deleteGroup(){
      this.$store.commit("deleteGroup", {g: this.g});
    },
    updateGroupWeight(e, g){
      let v = e.target.value;
      this.$store.commit("updateGroupWeight", {v, g})
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.sets{
  width: 320px;
  max-width: 90%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 70px 1fr;
  background: $midblue;
  @include biground;
  
  input{
    display: block;
    background: $light;
    border-radius: 5px;
    padding: 10px 15px;
    border: none;
    display: block;
    width: 100%;
  }
  button, .button{
    border-radius: 5px;
    margin-top: 0;
    margin-bottom: 0;
    &.delete{
      background: $red;
    }
  }

  input[type="checkbox"]{ display: none; }

  &.editing{
    grid-template-columns: 1fr 1fr;
    background: #fff;
    color: $black;
  }
  
  &--weight{
    align-items: center;
    display: flex;
    font-size: 23px;
    font-family: 'Anton', sans-serif;
  }
  
  &--complete{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
  }
  
  &--set{
    display: block;
    padding-bottom: 100%;
    height: 0px;
    border-radius: 50%;
    background: $red;
    position: relative;
  }
}

</style>