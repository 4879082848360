<template>
  <div>
    <router-link :to="'/list/' + workout.id" class="workout-item" v-for="workout in workouts" :key="'wid'+workout.id">
      <span>{{ workout.d }}</span>
      <span>Groups: {{ workout.groups.length }}</span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default{
  computed: {
    ...mapState(['workouts']),
    dateOrder(){
      return this.workouts.reverse()
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";


.workout-item{
  display: block;
  background: $midblue;
  @include biground;
  padding: 25px 25px;
  margin-top: 20px;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
}


.form{
  background: #fff;
  color: $black;
  padding: 15px;
  border-radius: 15px;
  text-align: left;
}

input{
  display: block;
  background: $light;
  border-radius: 5px;
  padding: 15px;
  border: none;
  display: block;
  width: 100%;
}

</style>